// 引入api
import { fun_GetAction, fun_PostAction } from "@/api";
// 引入加密方法
import "@/utils/security.js";
// 默认导出
export default {
    // 数据存储对象
    data: () => {
        return {
            // 抽屉状态
            drawerStatus: false,
            // 抽屉确定按钮loading状态
            loading: false,
            provinceOptions: [],
            cityOptions: [],
            // 查询表单字段
            form: {
                id: null, // id
                platformCode: null, // 交易平台编号
                platformName: null, // 交易平台名称
                platformUrl: null, // 交易平台访问地址
                platformProvince: null,// 交易中心所属省份
                platformCity: null // 交易中心所属市
            },
            // 表单提交返回的错误消息
            errRules: {
                platformCode: null, // 交易平台编号
                platformName: null, // 交易平台名称
                platformUrl: null, // 交易平台访问地址
                platformProvince: null,// 交易中心所属省份
                platformCity: null // 交易中心所属市
            },
            // 详情表单基础数据
            formConfigList: [{
                    label: "中心名称：",
                    value: "platformCode",
                    type: "select",
                    rule: {
                        required: true,
                        message: "请选择交易中心",
                        trigger: "change"
                    },
                    queryParams: {
                        sqlId: "201907041546460002",
                        dictionaryId: "ff48827f-50cc-40fd-885a-9cf97a1e5555"
                    }
                },
                {
                    label: "访问地址：",
                    value: "platformUrl",
                    type: "input",
                    rule: {
                        required: true,
                        message: "请输入交易中心访问地址",
                        trigger: "blur"
                    }
                },
                {
                    label: "所属省份：",
                    value: "platformProvince",
                    type: "slot",
                    slotName: "proSelect",
                    rule: {
                        required: true,
                        message: "请输入所属省份",
                        trigger: "change"
                    }
                },
                {
                    type: "slot",
                    slotName: "citySelect",
                    label: "所属市：",
                    value: "platformCity",
                    rule: {
                        required: true,
                        message: "请输入所属市",
                        trigger: "change"
                    }
                },
                // {
                //     label: "所属省份：",
                //     value: "platformProvince",
                //     type: "select",
                //     required: true,
                //     clearable: true,
                //     rule: {
                //         required: true,
                //         message: "请输入所属省份",
                //         trigger: "blur"
                //     },
                //     queryParams: {
                //         sqlId: "2b29f7ef-34a0-486d-99aa-f13e7cf0a9d2",
                //         PID: "1"
                //     }
                // },
                // {
                //     label: "所属市：",
                //     value: "platformCity",
                //     type: "select",
                //     required: true,
                //     clearable: true,
                //     rule: {
                //         required: true,
                //         message: "请输入所属市",
                //         trigger: "blur"
                //     },
                //     queryParams: {}
                // },
            ],
            // 加密key
            key: null
        };
    },

    // 模板编译完成
    mounted() {
        this.fun_GetProvince();
    },

    // 自定义方法
    methods: {
        /**
         * 获取详情
         * @author 滕威
         */
        async fun_GetDetails(id) {
            this.form.id = id;
            // 请求，获取结果集
            let res = await fun_PostAction("/platformUrl/detail?id=" + id);
            // 解构res.data
            const { result, data, msg } = res.data;
            // 判断请求是否成功
            if (result) {
                if(data.platformProvince){
                    this.fun_ChangeProvince(data.platformProvince);
                }
                // 回显表单
                this.form = data;
            } else {
                // 请求失败，提示错误信息
                this.$message.error(msg);
            }
        },
        /**
         * 表单提交
         * @author 滕威
         */
        fun_SubmitForm() {
            console.log(this.form)
            delete this.form.children;
            // 校验
            this.$refs.form.$refs.enInform.validate(valid => {
                // 校验通过
                if (valid) {
                    this.form.platformName = this.$refs.form.$el[0].value;
                    // 打开确定按钮loading状态
                    this.loading = true;
                    // 发起请求，获取结果集
                    fun_PostAction("/platformUrl/saveOrUpdate", this.form)
                        .then(res => {
                            // 解构res.data
                            const { result, msg, data } = res.data;
                            // 判断请求是否成功
                            if (result) {
                                // 请求一览查询接口
                                this.$parent.fun_GetList();
                                // 关闭弹窗
                                this.drawerStatus = false;
                                // 提示信息
                                this.$message.success(msg);
                            } else {
                                // 如果存在返回数据
                                if (data != null) {
                                    // 定义rules为一个随机数
                                    this.errRules = Math.random();
                                    // 执行延迟方法
                                    this.$nextTick(() => {
                                        // 提示
                                        this.$message.error("请按提示修改");
                                        // 赋值错误消息
                                        this.errRules = data;
                                    });
                                } else {
                                    // 请求失败，提示信息
                                    this.$message.error(msg);
                                }
                            }
                            // 取消确定按钮loading状态
                            this.loading = false;
                        })
                        .catch(() => {
                            // 取消确定按钮loading状态
                            this.loading = false;
                        });
                } else {
                    // 校验不通过，返回false，页面给出提示
                    return false;
                }
            });
        },
        /**
         * 抽屉关闭时执行
         * @author 滕威
         */
        fun_DrawerClose() {
            // 清空所属机构和角色下拉框
            // 遍历formItem
            this.$refs.form.$refs.formItem.map(e => {
                // 找到所属机构和角色下拉框
                if (e.prop === "platformCode" || e.prop === "platformProvince" || e.prop === "platformCode") {
                    // 置空
                    e.$children[1].value = null;
                }
            });
            // 清除表单验证和数据
            this.$refs.form.$refs.enInform.resetFields();
            // 关闭时，清除勾选
            this.$parent.$refs.list.$refs.multipleTable.clearSelection();
        },
        /**
         * 选择下拉框事件
         * @param {e} 选择的value值
         * @param {val} 下拉框的字段
         * @author
         */
        async fun_GetProvince() {
            const queryParams = {
                sqlId: "2b29f7ef-34a0-486d-99aa-f13e7cf0a9d2",
                PID: "1"
            }
            let res = await fun_PostAction("/combobox", queryParams);
            const { result, data } = res.data;
            if (result) {
                this.provinceOptions = data;
            }
        },
        fun_ChangeProvince(e) {
            this.form.platformCity = null;
            const queryParams = {
                sqlId: "2b29f7ef-34a0-486d-99aa-f13e7cf0a9d2",
                PID: e
            }
            fun_PostAction("/combobox", queryParams).then(res => {
                const { result, data } = res.data;
                if (result) {
                    this.cityOptions = data;
                }
            });
        },
        /**
         * 选择下拉框事件
         * @param {e} 选择的value值
         * @param {val} 下拉框的字段
         * @author 滕威
         */
        fun_GetSelectVal(e, val) {
            // 遍历查询表单对象
            for (const key in this.form) {
                // 判断key和val是否相同
                if (key == val) {
                    // 相同则复制给表单
                    this.form[key] = e;
                }
            }
        }
    }
};